import React, { useState } from 'react'
import AlertContext from "./AlertContext";

export default function AlertState(props) {

    const [alert, setAlert] = useState({
        type: "",
        text: "",
        gravity: "",
        position: ""
    });

    const showAlert = (type, text, gravity, position) => {
        setAlert({
            type: type,
            text: text,
            gravity: gravity,
            position: position
        })
    }

  return (
    <AlertContext.Provider value={{alert, showAlert}}>
        {props.children}
    </AlertContext.Provider>
  )
}
