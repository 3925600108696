import React, { useContext, useState } from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AlertContext from "../Components/Context/AlertContext";
import {html_beautify} from 'js-beautify';
import {js_beautify} from 'js-beautify';
import {css_beautify} from 'js-beautify';
import { useLocation } from "react-router-dom";

export default function Beatifier() {

    const location = useLocation();
    const pathName = location.pathname;
    const [code, setCode] = useState('');
    const context = useContext(AlertContext)
    const {showAlert} = context

    const beautifier = () => {
        try {            
            if (code === '') {
                showAlert("warn", "Please provide valid input", "bottom", "right")
                return;
            }
            let minifyString = code;
            let options = { indent_size: 4, space_in_empty_paren: true }
            let prettyString = pathName === '/htmlViewer'?html_beautify(minifyString, options):pathName === '/cssViewer'?css_beautify(minifyString, options):js_beautify(minifyString, options); 

            setCode(prettyString);
            showAlert("success", "Code beautify", "bottom", "right")
        } catch (error) {
            showAlert("error", error, "bottom", "right")
        }
    }

    const minifier = async () => {   
        try {                      
            if (code === '') {
                showAlert("warn", "Please provide valid input", "bottom", "right")
                return;
            }
            // let prettyString = code;
            // const options = {
            //     html: {
            //         removeAttributeQuotes: false,
            //         removeOptionalTags: false,
            //     },
            // };
            // let minifyString = minify(prettyString, options); 
            // setCode(minifyString);
            // showAlert("success", "Code minify", "bottom", "right")
        } catch (error) {
            showAlert("error", error, "bottom", "right")
        }
    }

    const clear = (e) => {
        setCode('')
        showAlert("success", "Cleared", "bottom", "right")
    }

    const alert = () => {
        showAlert("success", "Copy to clipboard", "bottom", "right")
    }

  return (
    <div className='container'>
        <h6>To reformatting your code, just copy + paste it below:</h6>
        <textarea className='form-control col-md-12' name="code" id="code" rows={15} value={code} onChange={(e) => {setCode(e.target.value)}}/>
        <button className="btn btn-success my-2 mx-2" onClick={beautifier}>Beautify</button>
        <button className="btn btn-secondary my-2 mx-2" onClick={clear}>Clear</button>
        <button className="btn btn-secondary my-2 mx-2" onClick={minifier}>Minify</button>
        <CopyToClipboard text={code} onCopy={alert}>
            <i className    ="fa-regular fa-copy fa-fade my-2 fa-2x float-right" title='Copy to clipboard' style={{color: '#000000', cursor: 'pointer'}}></i>
        </CopyToClipboard>
    </div>
  )
}
