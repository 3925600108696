import React, { useContext, useState } from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AlertContext from "../Components/Context/AlertContext";

export default function JsonFormatter() {
    const [code, setCode] = useState('');
    const [btnText, setBtnText] = useState('Compress');
    const context = useContext(AlertContext)
    const {showAlert} = context

    const valiJson = () => {
        try {            
            if (code === '') {
                showAlert("warn", "Please provide valid input", "bottom", "right")
                return;
            }
            let jsonString = code;
            let jsonPretty = JSON.stringify(JSON.parse(jsonString),null,2); 
            setCode(jsonPretty);
            showAlert("success", "JSON is valid", "bottom", "right")
        } catch (error) {
            showAlert("error", error, "bottom", "right")
        }
    }

    const formatJson = () => {   
        try {                      
            if (code === '') {
                showAlert("warn", "Please provide valid input", "bottom", "right")
                return;
            }
            let jsonString = code;
            if (btnText === 'Compress') { 
                let jsonPretty = JSON.stringify(JSON.parse(jsonString), null, 0); 
                setCode(jsonPretty);
                setBtnText('Prettify')
            } else {       
                let jsonPretty = JSON.stringify(JSON.parse(jsonString), null, 2); 
                setCode(jsonPretty);
                setBtnText('Compress')
            }
            showAlert("success", "Completed", "bottom", "right")
        } catch (error) {
            showAlert("error", error, "bottom", "right")
        }
    }

    const clear = (e) => {
        setCode('')
        showAlert("success", "Cleared", "bottom", "right")
    }

    const alert = () => {
        showAlert("success", "Copy to clipboard", "bottom", "right")
    }

  return (
    <div className='container'>
        <h6>To format and validate your JSON, just copy + paste it below:</h6>
        <textarea className='form-control col-md-12' name="code" id="code" rows={15} value={code} onChange={(e) => {setCode(e.target.value)}}/>
        <button className="btn btn-success my-2 mx-2" onClick={valiJson}>Validate JSON</button>
        <button className="btn btn-secondary my-2 mx-2" onClick={clear}>Clear</button>
        <button className="btn btn-secondary my-2 mx-2" onClick={formatJson}>{btnText}</button>
        <CopyToClipboard text={code} onCopy={alert}>
            <i className    ="fa-regular fa-copy fa-fade my-2 fa-2x float-right" title='Copy to clipboard' style={{color: '#000000', cursor: 'pointer'}}></i>
        </CopyToClipboard>
    </div>
  )
}
