import React from 'react'
import logo from '../../logo.svg';
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
        <footer className="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <p className="col-md-4 mb-0 text-muted">© {new Date().getFullYear()} tooltips.in</p>
            <span className="col-md-4 text-center">Built with <i className="fa-solid fa-heart fa-beat text-danger"></i> & <i className="fa-solid fa-mug-saucer"></i> for you....</span>
            <Link to="/" className="col-md-4 link-dark text-decoration-none">
            <img src={logo} alt="tooltips logo" height="45" className="d-inline-block align-text-center float-right"/>
            </Link>
        </footer>
    </div>
  )
}
