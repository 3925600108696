import React from 'react'
import { Link } from "react-router-dom";

export default function Card(props) {
  return (
    <>
        <Link to={props.link} style={{textDecoration: 'none'}}>
            <div className="card mb-3 my-1">
                <div className="row g-0">
                <div className="col-md-1"></div>
                <div className="col-md-3">
                    <img src={props.image} style={{height: '100px'}} className="img-fluid rounded-start" alt={props.tag}/>
                </div>
                <div className="col-md-8">
                    <div className="card-body">
                    <h5 className="card-title">{props.name}</h5>
                    <span className="float-right"><i className="fa-solid fa-arrow-right fa-2x"></i></span>
                    </div>
                </div>
                </div>
            </div>
        </Link>
    </>
  )
}
