import React from 'react'
import { Link, useLocation } from "react-router-dom";

export default function Breadcrum() {

    const location = useLocation();
    const pathName = location.pathname;

  return (
    pathName !== "/"?<div className="container my-3"><Link className="navbar-brand breadcrum" to="/"><i className='fa fa-home'></i> Home</Link><span> {pathName.replace('/', '/ ')}</span></div>:""
  )
}
