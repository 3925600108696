import './App.css';
import AlertState from './Components/Context/AlertState';
import JsonFormatter from './Components/JsonFormatter';
import Footer from './Components/Main/Footer';
import Home from './Components/Main/Home';
import Navbar from './Components/Main/Navbar';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ToastAlert from './Components/Main/ToastAlert';
import Breadcrum from './Components/Main/Breadcrum';
import Beatifier from './Components/Beatifier';

function App() {
  return (
    <AlertState>
    <Router>
      <Navbar/>
      <Breadcrum/>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/JSONFormatter" element={<JsonFormatter/>}/>
        <Route exact path="/HTMLViewer" element={<Beatifier/>}/>
        <Route exact path="/CSSViewer" element={<Beatifier/>}/>
        <Route exact path="/JSViewer" element={<Beatifier/>}/>
      </Routes>
      <Footer/>
      <ToastAlert/>
    </Router>
    </AlertState>
  );
}

export default App;
