import React from 'react'
import logo from '../../logo.svg';
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg" style={{backgroundColor:'#e3f2fd'}}>
        <div className="container-xxl   ">
            <Link className="navbar-brand" to="/">
                <img src={logo} alt="tooltips logo" width="30" height="24" className="d-inline-block align-text-top"/>
                tooltips
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    
                </ul>
                {/* <form className="d-flex" role="search">
                    <input className="form-control me-2" type="search" placeholder="Search here..." aria-label="Search"/>
                </form> */}
            </div>
        </div>
    </nav>
  )
}
