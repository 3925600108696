import React, { useContext, useEffect } from 'react'
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import AlertContext from "../Context/AlertContext";

export default function ToastAlert() {
    const context = useContext(AlertContext)
    const {alert} = context
    useEffect(() => {
        let type = alert.type;
        let text = alert.text;
        let gravity = alert.gravity;
        let position = alert.position;
        if (type === 'success') {
            Toastify({
                text: text,            
                duration: 3000,
                close: true,
                gravity: gravity,
                position: position,
                stopOnFocus: true,
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },           
            }).showToast();
        } else if (type === 'error') {
            Toastify({
                text: text,            
                duration: 3000,
                close: true,
                gravity: gravity,
                position: position,
                stopOnFocus: true,
                style: {
                    background: "linear-gradient(to right, #df1b1b, #fe8181)",
                },            
            }).showToast();
        } else if (type === 'warn') {
            Toastify({
                text: text,            
                duration: 3000,
                close: true,
                gravity: gravity,
                position: position,
                stopOnFocus: true,
                style: {
                    background: "linear-gradient(to right, #e7a300, #f2db00)",
                    color: "#000000",
                },            
            }).showToast();
        }
    }, [alert])

  return (
    <>
      
    </>
  )
}
