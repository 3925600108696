import React, { useState } from 'react'
import data from './menus.json'
import Card from './Card'

export default function Home() {



  const [cardData, setCardData] = useState(data);

  const filterCards = (e) => {
    setCardData(data.filter((c) => c.name.toLowerCase().startsWith(e.target.value.toLowerCase())));
  }

  return (
    <>       
        <div className="text-bg-light p-3 pb-5">
            <h1 className="text-center">tooltips</h1>
            <p className='text-center'>Code Formatter, JSON Beautifier, HTML Beautifier, CSS Beautifier and more...</p>
            <div className="container">
                <input onChange={filterCards} className="form-control form-control-lg me-2" type="search" placeholder="Search tools here..." aria-label="Search"/>
            </div>
        </div>      
        <div className='container my-1'> 
            <div className='row'>
                <h1 className="text-center">Popular Tools</h1>
                {cardData.map((obj) => {
                    return  <div className='col-md-4' key={Math.random()}><Card name={obj.name} image={obj.image} link={obj.link} head={obj.head} tag={obj.tag}/></div>
                })}
            </div>
        </div>
      </>
  )
}
